<template>
  <ion-page>
    <ion-header>
       <header-toolbar>
     
        <ion-title  :style="getTextStyle"> Share </ion-title>
     
       </header-toolbar>
    </ion-header>
    <ion-content :fullscreen="true" :style="contentStyle">
     Share Current Card
     <ion-button @click="shareCuratedContent2()">Share</ion-button>
    </ion-content>
  </ion-page>
</template>

<script >
import HeaderToolbar from "../components/HeaderToolBar.vue";
import {useStore } from "vuex";
import {
 
  IonPage,
  IonContent,
  IonHeader,
  IonTitle,

  IonButton,
} from "@ionic/vue";
import { useRouter } from "vue-router";
import { Share } from "@capacitor/share";
import {
  shareOutline,
  eyeOutline
} from "ionicons/icons";

export default {
  name: "SharePage",
  components: {
    IonContent,

    IonPage,
    IonHeader,
    IonTitle,
 
  
    IonButton,
    HeaderToolbar,

  },
 
  
  setup() {
    const store = useStore();
    const { push } = useRouter();
    const contentStyle = store.state.content;
    const menuStyle = store.state.menu;
    const updateShare = () => store.dispatch('axUpdateShare');
    const setContent = () => store.dispatch('currentCuratedContent');

    const cleanText = (text)=> text.replace(/<[^>]*>?/gm, " ");
    const shareCuratedContent2 = async () =>{
        await Share.share({
        title: "TK2P",
        text: cleanText("Please Visit My Content"),
        dialogTitle: "Share with buddies",
      });

      await updateShare({programItemId: 16, userId: this.currentUser.id})
    }
    const shareCuratedContent = async () =>{
        if (navigator.share) {
          navigator.share({
          title: 'web.dev',
          text: 'Check out web.dev.',
          url: 'https://web.dev/',
        })
          .then(() => console.log('Successful share'))
          .catch((error) => console.log('Error sharing', error));
      }
    }
 

    return {
      contentStyle,
      menuStyle,
      push,
      eyeOutline,
      shareOutline, shareCuratedContent,shareCuratedContent2, cleanText, updateShare, setContent
    };
  },
};
</script>
<style scoped>
.program-card ion-card-header {
  padding-bottom: 5px;
}
.program-card-img {
  height: 195px;
  object-fit: cover;
  object-position: top;
}
.share-image {
  display: flex;
  justify-content: center;
}
</style>