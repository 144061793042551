<template>
  <ion-toolbar :style="getMenuStyle">
    <slot></slot>
  </ion-toolbar>
</template>

<script>
import { mapGetters } from "vuex";
import {IonToolbar} from  "@ionic/vue";

export default {
  name: "HeaderToolbar",
  components: {IonToolbar},
  computed: {
    ...mapGetters({appBranding: 'getAppBranding'}),
    getMenuStyle() {
      return `--background: ${this.appBranding.menuBackgroundColor}; --color: ${this.appBranding.menuTextColor}`;
    },
  },
  setup() {
    return {
    }
  }
};
</script>

<style>
</style>